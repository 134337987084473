import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  fill: "none"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "url(#a)",
      d: "M11.046 6.674q.474-.074.953-.072c2.86 0 5.55 2.06 7.116 5.397q-.36.762-.81 1.476a.9.9 0 1 0 1.53.944q.628-.99 1.088-2.069a.9.9 0 0 0 0-.71c-1.817-4.22-5.236-6.837-8.924-6.837a7 7 0 0 0-1.26.108.912.912 0 1 0 .307 1.799zm-6.504-3.41a.903.903 0 0 0-1.277 1.278l2.788 2.78a13.2 13.2 0 0 0-2.977 4.317.9.9 0 0 0 0 .72c1.817 4.219 5.235 6.837 8.923 6.837a8.33 8.33 0 0 0 4.543-1.386l2.914 2.924A.902.902 0 0 0 21 20.095a.9.9 0 0 0-.266-.639zm5.721 8.268 2.204 2.203q-.23.066-.468.063a1.8 1.8 0 0 1-1.736-2.267M12 17.397c-2.86 0-5.55-2.06-7.106-5.398a10.9 10.9 0 0 1 2.429-3.41L8.914 10.2a3.598 3.598 0 0 0 4.884 4.885l1.43 1.412a6.5 6.5 0 0 1-3.229.9"
    }, null, -1),
    _createElementVNode("defs", null, [
      _createElementVNode("linearGradient", {
        id: "a",
        x1: "3",
        x2: "21.733",
        y1: "7.75",
        y2: "9.223",
        gradientUnits: "userSpaceOnUse"
      }, [
        _createElementVNode("stop", { "stop-color": "#EA7C4F" }),
        _createElementVNode("stop", {
          offset: "1",
          "stop-color": "#EB493D"
        })
      ])
    ], -1)
  ])))
}
export default { render: render }