import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "16",
  height: "16",
  fill: "none"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#6D6D7A",
      "fill-rule": "evenodd",
      d: "M4.912 5.155a.5.5 0 0 1 0 1h-.628c-1.076 0-1.95.874-1.95 1.95v3.256c0 1.08.877 1.957 1.956 1.957h7.427c1.074 0 1.95-.876 1.95-1.95V8.112c0-1.08-.878-1.957-1.956-1.957h-.622a.5.5 0 0 1 0-1h.622a2.96 2.96 0 0 1 2.956 2.957v3.256a2.954 2.954 0 0 1-2.95 2.95H4.29a2.96 2.96 0 0 1-2.956-2.957V8.104a2.953 2.953 0 0 1 2.95-2.949zM8 2a.5.5 0 0 1 .5.5v6.817l1.09-1.094a.5.5 0 1 1 .708.706l-1.94 1.947a1 1 0 0 1-.061.053l.057-.049a.5.5 0 0 1-.266.14q-.006 0-.012.002a1 1 0 0 1-.076.005l-.027-.002-.041-.002-.009-.002.077.006a.5.5 0 0 1-.354-.146v-.001L5.703 8.929a.5.5 0 0 1 .708-.706L7.5 9.317V2.5A.5.5 0 0 1 8 2",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }