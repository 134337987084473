import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "18",
  height: "18",
  fill: "none"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#EA4141",
      d: "M9 0C4.037 0 0 4.037 0 9s4.037 9 9 9 9-4.037 9-9-4.037-9-9-9m0 16.875c-4.342 0-7.875-3.533-7.875-7.875S4.658 1.125 9 1.125 16.875 4.658 16.875 9 13.342 16.875 9 16.875"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#F8F8F8",
      d: "M9 0C4.037 0 0 4.037 0 9s4.037 9 9 9 9-4.037 9-9-4.037-9-9-9m0 16.875c-4.342 0-7.875-3.533-7.875-7.875S4.658 1.125 9 1.125 16.875 4.658 16.875 9 13.342 16.875 9 16.875"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#EA4141",
      d: "M9.563 3.375H8.437v5.858l3.54 3.54.796-.796-3.21-3.21z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#F8F8F8",
      d: "M9.563 3.375H8.437v5.858l3.54 3.54.796-.796-3.21-3.21z"
    }, null, -1)
  ])))
}
export default { render: render }