import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  fill: "none"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#fff",
      d: "M16.9 6.4h-1.4v-.7a.7.7 0 1 0-1.4 0v.7H9.9v-.7a.7.7 0 1 0-1.4 0v.7H7.1A2.1 2.1 0 0 0 5 8.5v8.4A2.1 2.1 0 0 0 7.1 19h9.8a2.1 2.1 0 0 0 2.1-2.1V8.5a2.1 2.1 0 0 0-2.1-2.1m.7 10.5a.7.7 0 0 1-.7.7H7.1a.7.7 0 0 1-.7-.7V12h11.2zm0-6.3H6.4V8.5a.7.7 0 0 1 .7-.7h1.4v.7a.7.7 0 1 0 1.4 0v-.7h4.2v.7a.7.7 0 1 0 1.4 0v-.7h1.4a.7.7 0 0 1 .7.7z"
    }, null, -1)
  ])))
}
export default { render: render }