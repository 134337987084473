import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  fill: "none"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "url(#a)",
      d: "m12.753 15.552 5.65-5.66a1.001 1.001 0 1 0-1.41-1.42l-5 4.95-4.95-4.95a1 1 0 0 0-1.41 0 1 1 0 0 0-.3.71 1 1 0 0 0 .3.71l5.65 5.66a1 1 0 0 0 1.47 0"
    }, null, -1),
    _createElementVNode("defs", null, [
      _createElementVNode("linearGradient", {
        id: "a",
        x1: "15.172",
        x2: "14.808",
        y1: "8.181",
        y2: "16.22",
        gradientUnits: "userSpaceOnUse"
      }, [
        _createElementVNode("stop", { "stop-color": "#EA7C4F" }),
        _createElementVNode("stop", {
          offset: "1",
          "stop-color": "#EB493D"
        })
      ])
    ], -1)
  ])))
}
export default { render: render }