import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "20",
  height: "20",
  fill: "none"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#6D6D7A",
      d: "M10 2c-4.421 0-8 3.578-8 8 0 4.421 3.578 8 8 8 4.421 0 8-3.578 8-8 0-4.421-3.578-8-8-8m0 14.75A6.746 6.746 0 0 1 3.25 10c0-3.73 3.019-6.75 6.75-6.75s6.75 3.019 6.75 6.75-3.019 6.75-6.75 6.75m1.854-3.062c0 .345-.28.624-.625.624H8.771a.625.625 0 1 1 0-1.25h.604V9.397h-.604a.625.625 0 1 1 0-1.25H10c.345 0 .625.28.625.625v4.291h.604c.345 0 .625.28.625.626M9.22 5.865a.781.781 0 1 1 1.562 0 .781.781 0 0 1-1.562 0"
    }, null, -1)
  ])))
}
export default { render: render }