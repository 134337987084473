import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  fill: "none"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "url(#a)",
      d: "M20 1H4a3 3 0 0 0-3 3v16a3 3 0 0 0 3 3h16a3 3 0 0 0 3-3V4a3 3 0 0 0-3-3M8 21H4a1 1 0 0 1-1-1v-1.18A3 3 0 0 0 4 19h4zm0-4H4a1 1 0 0 1-1-1v-1.18A3 3 0 0 0 4 15h4zm0-4H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h4zm6 8h-4v-6h4zm0-8h-4V3h4zm7 7a1 1 0 0 1-1 1h-4v-2h4a3 3 0 0 0 1-.18zm0-4a1 1 0 0 1-1 1h-4v-2h4a3 3 0 0 0 1-.18zm0-4a1 1 0 0 1-1 1h-4V3h4a1 1 0 0 1 1 1zm-3-5a1 1 0 1 0 0 2 1 1 0 0 0 0-2M6 9a1 1 0 1 0 0-2 1 1 0 0 0 0 2"
    }, null, -1),
    _createElementVNode("defs", null, [
      _createElementVNode("linearGradient", {
        id: "a",
        x1: "23",
        x2: "-1.887",
        y1: "1",
        y2: "11.45",
        gradientUnits: "userSpaceOnUse"
      }, [
        _createElementVNode("stop", { "stop-color": "#81B652" }),
        _createElementVNode("stop", {
          offset: "1",
          "stop-color": "#9ECC73"
        })
      ])
    ], -1)
  ])))
}
export default { render: render }