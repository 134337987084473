import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "16",
  height: "16",
  fill: "none"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#6D6D7A",
      "fill-rule": "evenodd",
      d: "M4.387 5.152c-.95 0-1.72.773-1.72 1.714v2.677c0 .946.77 1.713 1.72 1.713h.72a.5.5 0 0 1 0 1h-.72a2.72 2.72 0 0 1-2.72-2.713V6.866a2.72 2.72 0 0 1 2.72-2.714h7.566a2.716 2.716 0 0 1 2.714 2.714v2.677c0 1.5-1.214 2.713-2.714 2.713h-.702a.5.5 0 1 1 0-1h.702c.947 0 1.713-.766 1.713-1.713V6.866c0-.943-.766-1.714-1.713-1.714z",
      "clip-rule": "evenodd"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#6D6D7A",
      "fill-rule": "evenodd",
      d: "M5.109 9.371a.5.5 0 0 1 .5.5v2.514c0 .49.397.886.887.886h3.343c.489 0 .886-.396.886-.886V9.87a.5.5 0 1 1 1 0v2.514a1.886 1.886 0 0 1-1.886 1.886H6.496a1.887 1.887 0 0 1-1.887-1.886V9.87a.5.5 0 0 1 .5-.5",
      "clip-rule": "evenodd"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#6D6D7A",
      "fill-rule": "evenodd",
      d: "M4.025 9.871a.5.5 0 0 1 .5-.5h7.285a.5.5 0 0 1 0 1H4.525a.5.5 0 0 1-.5-.5M10.027 7.035a.5.5 0 0 1 .5-.5h1.414a.5.5 0 1 1 0 1h-1.414a.5.5 0 0 1-.5-.5M6.496 2.727a.887.887 0 0 0-.887.886V4.66a.5.5 0 1 1-1 0V3.613c0-1.041.845-1.886 1.887-1.886H9.84c1.042 0 1.886.845 1.886 1.886V4.66a.5.5 0 0 1-1 0V3.613a.887.887 0 0 0-.886-.886z",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }