import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  fill: "none"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#6D6D7A",
      d: "M20.925 11.65C19.108 7.546 15.689 5 12 5s-7.108 2.546-8.925 6.65a.85.85 0 0 0 0 .7C4.892 16.454 8.311 19 12 19s7.108-2.546 8.925-6.65a.86.86 0 0 0 0-.7M12 17.25c-2.852 0-5.551-2.004-7.108-5.25C6.45 8.754 9.148 6.75 12 6.75s5.551 2.004 7.108 5.25c-1.557 3.246-4.256 5.25-7.108 5.25m0-8.75c-.712 0-1.408.205-2 .59a3.52 3.52 0 0 0-1.325 1.57 3.4 3.4 0 0 0-.205 2.023c.14.679.482 1.302.985 1.792a3.63 3.63 0 0 0 1.843.958 3.7 3.7 0 0 0 2.08-.2 3.6 3.6 0 0 0 1.614-1.289A3.43 3.43 0 0 0 15.6 12c0-.928-.38-1.819-1.054-2.475A3.65 3.65 0 0 0 12 8.5m0 5.25c-.356 0-.704-.103-1-.295a1.76 1.76 0 0 1-.662-.785 1.7 1.7 0 0 1-.103-1.011c.07-.34.241-.652.493-.896.251-.245.572-.412.921-.48.349-.067.71-.032 1.04.1.328.133.61.357.807.645a1.716 1.716 0 0 1-.224 2.21A1.83 1.83 0 0 1 12 13.75"
    }, null, -1)
  ])))
}
export default { render: render }